// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ActivatedRoute } from '@angular/router';

export const environment: {
  production: boolean;
  apiUrl: string;
  interval: number;
  clientData: any;
  current: string;
  buildType: string;
  isDev: boolean;
  actualView: string;
  beta: boolean;
  startDate: Date;
  endDate: Date;
  fontFamily: string;
  homeId: string;
  textColor: string;
  currentRoute?: ActivatedRoute;
  flavour: string;
} = {
  production: false,
  apiUrl: 'https://backend.testing.total-energies.hobeen.com',
  interval: 30000,
  clientData: undefined,
  current: 'Resumen',
  buildType: 'Build de Testing',
  isDev: true,
  actualView: 'local',
  beta: true,
  startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  endDate: new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
    0,
    0,
    0,
    0
  ),
  homeId: '',
  fontFamily: '"Nunito", sans-serif',
  textColor: '#374649',
  currentRoute: undefined,
  flavour: 'total',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
